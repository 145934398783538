import React, { useState } from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%;
    width: 100%;
    transition: transform 700ms ease;
    transform: translateX(${props => props.activeIndex * -100}%);
    ${'' /* @media (max-width: 768px) {
        min-height: 40vh;
    } */}
`;
export const CarouselItem = styled.div`
    display: flex;
    flex-direction: flex-start;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: translateX(${props => props.index * 100}%);
    position: absolute;
    ${props => (props.mobile ? props.mobile : '')}
`;

const CarouselIndicatorContainer = styled.div`
    display: flex;
    position: absolute;
    bottom: 20px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const CarouselIndicator = styled.div`
    display: flex;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: ${props => (props.active ? 'white' : 'rgba(255,255,255,0.3)')};
    bottom: 0;
    cursor: pointer;
    mix-blend-mode: difference;
`;

const ServicesCarousel = ({ children }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <>
            <CarouselContainer activeIndex={activeIndex}>
                <>{children}</>
            </CarouselContainer>
            <CarouselIndicatorContainer>
                {[...new Array(children.length)].map((d, i) => (
                    <CarouselIndicator
                        active={activeIndex === i}
                        onClick={() => setActiveIndex(i)}
                    />
                ))}
            </CarouselIndicatorContainer>
        </>
    );
};

export default ServicesCarousel;
