import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { InlineWidget } from 'react-calendly';
import Carousel, { CarouselItem } from '../components/ServicesCarousel';
import TeamGif from './../assets/images/team.gif';
import team_02 from './../assets/images/services/team_02.jpg';
import team_03 from './../assets/images/services/team_03.jpg';
import team_04 from './../assets/images/services/team_04.jpg';
import Logo from './../assets/images/logo.png';
import BulletImg from './../assets/images/services/arrow.svg';
import RoundedBulletImg from './../assets/images/services/rounded-arrow.svg';
import DoubleArrowImg from './../assets/images/services/double-arrow.svg';
import firstPartyImg from './../assets/images/services/firstparty.png';
import thirdpartyImg from './../assets/images/services/thirdparty.png';
import cognitiveImg from './../assets/images/services/cognitive.png';
import contextualImg from './../assets/images/services/contextual.png';
import newAudienceImg from './../assets/images/services/new_audiences.png';
import ownedAssetsImg from './../assets/images/services/owned_assets.png';
import integratedAssetsImg from './../assets/images/services/integrated.jpg';
import dataCreativityAssetsImg from './../assets/images/services/data-creativity.jpg';
import audigentIconAssetsImg from './../assets/images/services/audigent-icon.svg';
import plusAssetsImg from './../assets/images/services/plus.svg';
import circleAssetsImg from './../assets/images/services/circle.svg';
import awardAssetImg_01 from './../assets/images/services/award_01.png';
import awardAssetImg_02 from './../assets/images/services/award_02.png';
import awardAssetImg_03 from './../assets/images/services/award_03.png';
import awardAssetImg_04 from './../assets/images/services/award_04.png';
import awardAssetImg_05 from './../assets/images/services/award_05.png';
import awardAssetImg_06 from './../assets/images/services/award_06.png';
import awardAssetImg_07 from './../assets/images/services/award_07.png';
import awardAssetImg_08 from './../assets/images/services/award_08.png';
import awardBadgeAssetImg_01 from './../assets/images/services/badge_01.png';
import awardBadgeAssetImg_02 from './../assets/images/services/badge_02.png';
import awardBadgeAssetImg_03 from './../assets/images/services/badge_03.png';
import awardBadgeAssetImg_04 from './../assets/images/services/badge_04.png';
import awardBadgeAssetImg_05 from './../assets/images/services/badge_05.png';
import integratedTeamAssetImg from './../assets/images/services/integrated_team.png';
import channelLessAssetImg from './../assets/images/services/channel-less.png';
import flawlessExecutionAssetImg from './../assets/images/services/flawless-execution.png';
import advancedReportingAssetImg from './../assets/images/services/advanced_reporting.png';
import audienceInsightsAssetImg from './../assets/images/services/audience_insight.png';
import purchaserInsightsAssetImg from './../assets/images/services/purchaser_insights.png';
import audienceSegmentAssetImg from './../assets/images/services/audience_segment.png';
import campaignOptimizationsAssetImg from './../assets/images/services/campaign_optimizations.png';
import Layout from '../layout/main';

const AwardAssets = [
    awardAssetImg_01,
    awardAssetImg_02,
    awardAssetImg_03,
    awardAssetImg_04,
    awardAssetImg_05,
    awardAssetImg_06,
    awardAssetImg_07,
    awardAssetImg_08,
];
const AwardBadgesAssets = [
    awardBadgeAssetImg_01,
    awardBadgeAssetImg_02,
    awardBadgeAssetImg_03,
    awardBadgeAssetImg_04,
    awardBadgeAssetImg_05,
];

const marqeeAnimation = keyframes`
 from{ transform: translateX(0); }
 to { transform: translateX(-100%) }`;

const Marquee = styled.div`
    position: relative;
    height: 50px;
    width: 100%;
    > div {
        animation: ${marqeeAnimation} 15s linear infinite;
    }
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: ${props => (props.row ? 'row' : 'column')};
    flex: ${props => (props.flex ? props.flex : 'initial')};
    align-self: ${props => (props.stretch ? 'stretch' : 'auto')};
    gap: ${props => (props.gap ? props.gap : 0)};
    img {
        display: flex;
    }
    @media (max-width: 768px) {
        ${props => (props.mobile ? props.mobile : '')}
    }
`;

const Section = styled.div`
    position: relative;
    overflow: hidden;
    font-family: 'Forma DJR Banner';
    font-style: normal;
    font-weight: 500;
    height: ${props => (props.fullHeight ? '100vh' : 'auto')};
    width: ${props => (props.container ? '100%' : 'auto')};
    background-color: ${props =>
        props.transparent ? 'transparent' : props.light ? '#fff' : '#000'};
    color: ${props => (props.light ? '#000' : '#fff')};
    padding: ${props => (props.container ? 0 : 'clamp(40px, 4vw, 75px)')};
    display: flex;
    flex: 1;
    flex-direction: ${props => (props.row ? 'row' : 'column')};
    border-box: content-box;
    align-items: ${props => (props.center ? 'center' : 'flex-start')};
    @media (max-width: 768px) {
        ${props => (props.mobile ? props.mobile : '')}
    }
`;

const Flex = styled.div`
    display: flex;
    flex-direction: ${props => (props.row ? 'row' : 'column')};
    flex: ${props => (props.flex ? props.flex : 'initial')};
    align-items: ${props => (props.center ? 'center' : 'initial')};
    justify-items: ${props => (props.center ? 'center' : 'initial')};
`;

const Text = styled.div`
    font-family: 'Forma DJR Banner';
    font-style: normal;
    z-index: 2;
`;

const OutlinedText = styled.span`
    -webkit-text-stroke: 1px ${props => (props.light ? '#000' : '#fff')};
    color: transparent;
`;
const GradientText = styled.span`
    background: linear-gradient(90deg, #ba9765 0%, #745436 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Header = styled(Text)`
    margin: 0;
    padding: 0;
    font-weight: 500;
`;

const H1 = styled(Header)`
    font-size: clamp(25px, 8.75vw, 140px);
    line-height: clamp(25px, 8vw, 120px);
    color: ${props => (props.light ? '#000' : '#fff')};
    span {
        margin-right: clamp(5px, 2vw, 25px);
    }
    @media (max-width: 768px) {
        ${props => (props.mobile ? props.mobile : '')}
    }
`;
const H2 = styled(Header)`
    font-size: clamp(40px, 5.25vw, 92px);
    line-height: clamp(40px, 5.25vw, 90px);
    color: ${props => (props.light ? '#000' : '#fff')};
    span {
        margin-right: clamp(5px, 1.5vw, 15px);
    }
`;
const H3 = styled(Header)`
    font-size: clamp(20px, 3.25vw, 28px);
    line-height: clamp(20px, 3.25vw, 30px);
    color: ${props => (props.light ? '#000' : '#fff')};
    font-style: ${props => (props.italic ? 'italic' : 'normal')};
`;

const Paragraph = styled(Text)`
    font-family: 'Forma DJR Banner';
    font-style: normal;
    z-index: 2;
    display: flex;
    position: relative;
    font-weight: 400;
    font-size: clamp(18px, 2.25vw, 18px);
    font-style: ${props => (props.italic ? 'italic' : 'normal')};
    line-height: clamp(18px, 2.25vw, 29px);
    letter-spacing: 0.06em;
    width: ${props => (props.halfWidth ? '50%' : '100%')};
    flex-direction: ${props => (props.row ? 'row' : 'column')};
    align-items: ${props => (props.middle ? 'center' : 'flex-start')};
    box-sizing: border-box;
    margin: 25px 0;
`;
const ListHeader = styled(Text)`
    display: flex;
    position: relative;
    font-weight: 400;
    font-size: clamp(25px, 2.25vw, 25px);
    line-height: clamp(25px, 2.25vw, 28px);
    letter-spacing: 0.03em;
    width: ${props => (props.halfWidth ? '50%' : '100%')};
    flex-direction: ${props => (props.row ? 'row' : 'column')};
    align-items: ${props => (props.middle ? 'center' : 'flex-start')};
    box-sizing: border-box;
    margin: 5px 0;
    @media (max-width: 768px) {
        ${props => (props.mobile ? props.mobile : '')}
    }
`;

const ListItem = styled(Text)`
    display: flex;
    position: relative;
    font-weight: 300;
    font-size: clamp(25px, 2.25vw, 25px);
    line-height: clamp(25px, 2.25vw, 28px);
    letter-spacing: 0.03em;
    width: ${props => (props.halfWidth ? '50%' : '100%')};
    flex-direction: ${props => (props.row ? 'row' : 'column')};
    align-items: ${props => (props.middle ? 'center' : 'flex-start')};
    box-sizing: border-box;
    margin: 10px 0;
    cursor: ${props => (props.onClick ? 'pointer' : 'initial')};
`;

const Img = styled.img`
    width: 100%;
    filter: ${props => (props.grayscale ? 'grayscale(100%)' : 'none')};
    hover: {
        filter: none;
    }
`;

const Overlay = styled.div`
    position: absolute;
    inset: 0;
    background: ${props =>
        props.light ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'};
    z-index: 1;
`;

const Button = styled.a`
    font-family: 'Forma DJR Banner';
    display: flex;
    font-size: 18px;
    font-weight: 400;
    background: white;
    text-decoration: none;
    color: black;
    padding: 15px 15%;
    margin: 10px 0;
    cursor: pointer;
    justify-content: center;
`;

const BackgroundImage = styled.div`
    position: absolute;
    background-repeat: no-repeat;
    background-size: ${props => (props.size ? props.size : 'contain')};
`;

const CenteredBackgroundImage = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${props => (props.url ? props.url : '')});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
`;
const Stars = ({ row }) => (
    <Container row={row} gap="15px">
        {[...new Array(7)].map(d => (
            <img src={plusAssetsImg} width="15" alt="" />
        ))}
    </Container>
);

const CalendlyModal = ({ onClose }) => (
    <Overlay style={{ zIndex: 100 }} onClick={onClose}>
        <div
            style={{
                marginTop: '15%',
                position: 'fixed',
                inset: 0,
                zIndex: 100,
            }}
        >
            <InlineWidget url="https://calendly.com/dave_au/managed-service-discovery" />
        </div>
    </Overlay>
);

export default function Services() {
    const [scheduleMeeting, setScheduleMeeting] = useState(false);

    const scrollToElement = id => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <Layout mainBackgroundColor={'#000'}>
            <Container style={{ paddingTop: 100 }}>
                {scheduleMeeting && (
                    <CalendlyModal onClose={() => setScheduleMeeting(false)} />
                )}
                <Section id="top" style={{ display: 'none' }}>
                    <img src={Logo} alt="logo" />
                </Section>
                <Section fullHeight>
                    <BackgroundImage
                        style={{
                            right: 0,
                            bottom: -20,
                            overflow: 'hidden',
                            height: '40%',
                            width: '40%',
                            zIndex: 1,
                            backgroundImage: `url(${audigentIconAssetsImg})`,
                            backgroundPosition: 'bottom right',
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            right: 15,
                            width: 20,
                            bottom: 0,
                            top: 0,
                        }}
                    >
                        <Stars />
                    </div>
                    <BackgroundImage
                        size="cover"
                        style={{
                            right: 50,
                            top: '20%',
                            bottom: 0,
                            overflow: 'hidden',
                            width: '45%',
                            zIndex: 0,
                            backgroundImage: `url(${integratedAssetsImg})`,
                            backgroundPosition: 'rigth',
                        }}
                    >
                        <Overlay />
                    </BackgroundImage>
                    <H1>
                        <OutlinedText>DATA DRIVEN</OutlinedText>
                        <br />
                        <span>INTEGRATED SOLUTIONS</span>
                    </H1>
                    <Paragraph row container>
                        <Paragraph halfWidth>
                            Audigent partners with organizations to superpower
                            their audience data capabilities with performance
                            driven media and creative from one connected and
                            accountable team.
                        </Paragraph>
                        <span></span>
                    </Paragraph>
                    {[
                        {
                            label: 'SCHEDULE A MEETING',
                            onClick: () => setScheduleMeeting(true),
                        },
                        {
                            label: 'OUR TEAM',
                            onClick: () =>
                                (window.location.href = '/about-us/'),
                        },
                    ].map(d => (
                        <ListItem row middle onClick={d.onClick}>
                            <img
                                src={RoundedBulletImg}
                                width="50"
                                style={{ marginRight: 10 }}
                                alt={d.label}
                            />
                            {d.label}
                        </ListItem>
                    ))}
                </Section>
                <Section light>
                    <H2 light>
                        <span>boutique data agency with large agency</span>
                        <GradientText>capabilities</GradientText>
                    </H2>
                    <Paragraph italic>
                        Data-Driven Media & Creative Activation that Drives
                        Performance & Efficiency
                    </Paragraph>
                    <Marquee>
                        <Container stretch row>
                            {[
                                'Premium, Exclusive Data',
                                'Performance Strategy',
                                'Connected Creative',
                                'MEDIA ACTIVATION',
                                'Analytics, Reporting & Data Science',
                            ].map(label => (
                                <Paragraph
                                    style={{
                                        padding: '0 4%',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {label.toUpperCase()}
                                </Paragraph>
                            ))}
                            {[
                                'Premium, Exclusive Data',
                                'Performance Strategy',
                                'Connected Creative',
                                'MEDIA ACTIVATION',
                                'Analytics, Reporting & Data Science',
                            ].map(label => (
                                <Paragraph
                                    style={{
                                        padding: '0 4%',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {label.toUpperCase()}
                                </Paragraph>
                            ))}
                        </Container>
                    </Marquee>
                </Section>
                <Section
                    fullHeight
                    mobile={{ backgroundColor: '#fff', color: '#000' }}
                >
                    <H1 mobile={{ color: '#000' }}>
                        <span>end-to-end</span>
                        <GradientText>connected</GradientText>
                        <br />
                        <span>
                            solutions begin with world <br />
                            class data capabilities
                        </span>
                    </H1>
                    <Paragraph>
                        We champion comprehensive, robust audience strategies,
                        cross-channel media planning and insight-driven
                        reporting. We are your partner to drive efficiency,
                        value and performance across digital media activations.
                    </Paragraph>
                    <Container row stretch mobile={{ display: 'none' }}>
                        <Container flex={1} style={{ alignItems: 'center' }}>
                            <Paragraph middle>FULLY INTEGRATED TEAM</Paragraph>
                            <img
                                src={integratedTeamAssetImg}
                                width="60%"
                                alt=""
                            />
                        </Container>
                        <img src={DoubleArrowImg} width="40" alt="" />
                        <Container flex={1} style={{ alignItems: 'center' }}>
                            <Paragraph middle>CROSS-CHANNEL PLANNING</Paragraph>
                            <img src={channelLessAssetImg} width="60%" alt="" />
                        </Container>
                        <img src={DoubleArrowImg} width="40" alt="" />
                        <Container flex={1} style={{ alignItems: 'center' }}>
                            <Paragraph middle>
                                PRE-EMINENT MEDIA ACTIVATION
                            </Paragraph>
                            <img
                                src={flawlessExecutionAssetImg}
                                width="60%"
                                alt=""
                            />
                        </Container>
                        <img src={DoubleArrowImg} width="40" alt="" />
                        <Container flex={1} style={{ alignItems: 'center' }}>
                            <Paragraph middle>ADVANCED REPORTING</Paragraph>
                            <img
                                src={advancedReportingAssetImg}
                                width="60%"
                                alt=""
                            />
                        </Container>
                    </Container>
                </Section>
                <Section fullHeight>
                    <H1>
                        <div>recognized by</div>
                        <GradientText>industry leaders</GradientText>
                        <br />
                        <span>
                            for solving today’s
                            <br />
                            biggest challenges
                        </span>
                    </H1>
                    <Paragraph>
                        Audigent’s one-of-a-kind offering that features both
                        campaign strategy and media activation services has
                        eliminated the need to rely on 3rd party data and
                        antiquated technology. Additionally, Audigent has
                        bolstered its creative resources to provide quick
                        turnaround and approval times. Overall, Audigent
                        provides a whole new level of transparency into our
                        processes and brings detailed insights and reporting to
                        every campaign.
                    </Paragraph>
                    <Container stretch row>
                        {AwardBadgesAssets.map((image, i) => (
                            <Flex
                                flex={1}
                                center
                                style={{
                                    // height: 400,
                                    justifyContent:
                                        i % 2 ? 'flex-end' : 'flex-start',
                                    marginTop: i % 2 ? '10vw' : 0,
                                }}
                            >
                                <img
                                    src={image}
                                    width="100%"
                                    alt=""
                                    style={{ maxWidth: 300 }}
                                />
                            </Flex>
                        ))}
                    </Container>
                    <Stars row />
                </Section>
                <Section container fullHeight>
                    <Section container>
                        <BackgroundImage
                            style={{
                                top: '5%',
                                left: '-10%',
                                height: '95%',
                                width: '45%',
                                backgroundImage: `url(${circleAssetsImg})`,
                                backgroundPosition: 'center',
                                transformOrigin: 'center',
                                transform: 'rotate(180deg)',
                            }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: '60%',
                                backgroundSize: 'contain',
                                transformOrigin: 'center',
                                transform: 'rotate(90deg)',
                            }}
                        >
                            <img src={RoundedBulletImg} width="50" alt="" />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                overflow: 'hidden',
                                height: '100%',
                                width: '55%',
                                zIndex: 0,
                                backgroundImage: `url(${dataCreativityAssetsImg})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '150%',
                                backgroundPosition: 'left',
                            }}
                        >
                            <Overlay />
                        </div>
                        <Section transparent>
                            <H1>
                                <OutlinedText>
                                    THE
                                    <br />
                                    INTERSECTION
                                    <br /> OF
                                </OutlinedText>
                                DATA +
                            </H1>
                            <H1>CREATIVITY</H1>
                        </Section>
                    </Section>
                    <Section transparent>
                        <H3 italic>
                            1ST PARTY DATA + AUDIENCE CURATION & MEDIA PLANNING
                            / ACTIVATION + CREATIVE & PRODUCTION
                        </H3>
                        <Paragraph>
                            Our hands on creative approach is connected to our
                            hands on keyboard trading, and we are highly success
                            driven and collaborative. We are also resourceful
                            with a brands existing assets just as much as we are
                            with new productions.
                        </Paragraph>
                        <Stars row />
                    </Section>
                </Section>
                <Container row mobile={`flex-direction: column`}>
                    <Section light>
                        <H2 light>
                            powering
                            <br /> programmatic
                            <br /> activations with
                            <br />
                            <GradientText>premium, exclusive</GradientText> data
                        </H2>
                        <Paragraph>
                            From exclusive first-party publisher data via our
                            next-gen DMP, to contextual and cognitive activation
                            capabilities, to best-in-class third-party data
                            assets, Audigent has robust targeting solutions for
                            brands and agencies across categories and verticals.
                        </Paragraph>
                    </Section>
                    <Section
                        container
                        center
                        mobile={`min-height: 400px; background: white`}
                    >
                        <Carousel>
                            <CarouselItem index={0}>
                                <Section
                                    center
                                    style={{ height: 'calc(100% - 150px)' }}
                                    mobile={`margin: 0% 5%; min-height: calc(100% - 150px);border-radius: 10px; text-align: center;`}
                                >
                                    <H2 style={{ marginBottom: '5%' }}>
                                        FIRST PARTY
                                    </H2>
                                    <CenteredBackgroundImage
                                        url={firstPartyImg}
                                    />
                                    {/* <img
                                        width="100%"
                                        src={firstPartyImg}
                                        alt={'firstParty'}
                                    /> */}
                                </Section>
                            </CarouselItem>
                            <CarouselItem index={1}>
                                <Section
                                    center
                                    style={{ height: 'calc(100% - 150px)' }}
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px);border-radius: 10px; text-align: center;`}
                                >
                                    <H2 style={{ marginBottom: '5%' }}>
                                        <OutlinedText>THIRD</OutlinedText>PARTY
                                    </H2>
                                    <CenteredBackgroundImage
                                        url={thirdpartyImg}
                                    />
                                    {/* <img
                                        width="100%"
                                        src={thirdpartyImg}
                                        alt={'thirdparty'}
                                    /> */}
                                </Section>
                            </CarouselItem>
                            <CarouselItem index={2}>
                                <Section
                                    center
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px);border-radius: 10px; text-align: center;`}
                                >
                                    <H2 style={{ marginBottom: '5%' }}>
                                        CONTEXTUAL
                                    </H2>
                                    <img
                                        height="40%"
                                        src={contextualImg}
                                        alt={'contextual'}
                                    />
                                    <Paragraph style={{ textAlign: 'center' }}>
                                        Audigent’s proprietary contextual tool
                                        analyzes millions of inventory sources
                                        to isolate the most contextually
                                        relevant sites and inventory for your
                                        media campaigns.
                                    </Paragraph>
                                </Section>
                            </CarouselItem>
                            <CarouselItem index={3}>
                                <Section
                                    center
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px);border-radius: 10px;text-align: center; `}
                                >
                                    <H2 style={{ marginBottom: '5%' }}>
                                        COGNITIVE
                                    </H2>
                                    <img
                                        width="25%"
                                        src={cognitiveImg}
                                        alt={'cognitive'}
                                    />
                                    {/* <CenteredBackgroundImage url={cognitiveImg} /> */}

                                    <Paragraph style={{ textAlign: 'center' }}>
                                        Powered by Comscore Predictive Audience
                                        technology, Audigent’s Cognitive
                                        activation product combines the power of
                                        incredible reach with Audigent’s
                                        curation capabilities to drive
                                        performance and efficiency.
                                    </Paragraph>
                                </Section>
                            </CarouselItem>
                            <CarouselItem index={4}>
                                <Section
                                    center
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px);border-radius: 10px; text-align: center; `}
                                >
                                    <H2 style={{ marginBottom: '5%' }}>
                                        <OutlinedText>OWNED</OutlinedText>ASSETS
                                    </H2>
                                    <img
                                        height="40%"
                                        src={ownedAssetsImg}
                                        alt={'ownedAssets'}
                                    />
                                    <Paragraph style={{ textAlign: 'center' }}>
                                        Audigent works with brands to onboard
                                        their powerful first-party data to make
                                        it actionable across the programmatic
                                        ecosystem.
                                    </Paragraph>
                                </Section>
                            </CarouselItem>
                            <CarouselItem index={5}>
                                <Section
                                    center
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px);border-radius: 10px; text-align: center;`}
                                >
                                    <H2 style={{ marginBottom: '5%' }}>
                                        <OutlinedText>NEW</OutlinedText>
                                        AUDIENCES
                                    </H2>
                                    <img
                                        height="25%"
                                        src={newAudienceImg}
                                        alt={'newAudience'}
                                    />
                                    <Paragraph style={{ textAlign: 'center' }}>
                                        By implementing Audigent’s Hadron ID
                                        across owned web and digital properties,
                                        brands are empowered with the ability to
                                        gain deep insights about their core
                                        audience as well as action them
                                        programmatically.
                                    </Paragraph>
                                </Section>
                            </CarouselItem>
                        </Carousel>
                    </Section>
                </Container>
                <Section fullHeight id="team" style={{ display: 'none' }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: '0%',
                            right: '-15%',
                            height: '100%',
                            width: '65%',
                            backgroundImage: `url(${circleAssetsImg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                        }}
                    />
                    <H1>
                        <OutlinedText>THE</OutlinedText>
                        <span>TEAM</span>
                    </H1>
                    <Paragraph>
                        From exclusive first-party publisher data via our
                        next-gen DMP, to contextual and cognitive activation
                        capabilities, to best-in-class third-party data assets,
                        Audigent has robust targeting solutions for brands and
                        agencies across categories and verticals.
                    </Paragraph>
                    <Section container row>
                        <Img grayscale src={TeamGif} width="100%" alt="" />
                        <Container gap="10px">
                            <Flex flex="1">
                                <img src={team_02} width="100%" alt="" />
                            </Flex>
                            <Container stretch row gap="10px">
                                <Flex>
                                    <img src={team_03} width="100%" alt="" />
                                </Flex>
                                <Flex>
                                    <img src={team_04} width="100%" alt="" />
                                </Flex>
                            </Container>
                        </Container>
                    </Section>
                    <Container stretch row mobile={`flex-direction:column`}>
                        <Flex flex="1" center>
                            <Button href="mailto:demo@example.com?Subject=Managed Services">
                                CONTACT US
                            </Button>
                        </Flex>
                        <Flex flex="1" center>
                            <Button onClick={() => setScheduleMeeting(true)}>
                                {'Schedule a Meeting'.toUpperCase()}
                            </Button>
                        </Flex>
                    </Container>
                </Section>

                <Section
                    container
                    center
                    mobile={`min-height: 75vw; min-height: 400px; background: white`}
                >
                    <Carousel>
                        <CarouselItem index={0}>
                            <Section
                                flex={1}
                                style={{ alignItems: 'center' }}
                                mobile={`margin: 0% 10%; height:calc(100% - 135px); max-width: 250px; min-height: 250px; border-radius: 10px; text-align:center; `}
                            >
                                <Paragraph middle>
                                    FULLY INTEGRATED TEAM
                                </Paragraph>
                                <CenteredBackgroundImage
                                    url={integratedTeamAssetImg}
                                />
                            </Section>
                        </CarouselItem>
                        <CarouselItem index={1}>
                            <Section
                                flex={1}
                                style={{ alignItems: 'center' }}
                                mobile={`margin: 0% 10%; height:calc(100% - 135px); border-radius: 10px; text-align:center;`}
                            >
                                <Paragraph middle>
                                    CROSS-CHANNEL PLANNING
                                </Paragraph>
                                <CenteredBackgroundImage
                                    url={channelLessAssetImg}
                                />
                            </Section>
                        </CarouselItem>
                        <CarouselItem index={2}>
                            <Section
                                flex={1}
                                style={{ alignItems: 'center' }}
                                mobile={`margin: 0% 10%; height:calc(100% - 135px); border-radius: 10px; text-align:center;`}
                            >
                                <Paragraph middle>
                                    PRE-EMINENT MEDIA ACTIVATION
                                </Paragraph>
                                <CenteredBackgroundImage
                                    url={flawlessExecutionAssetImg}
                                />
                            </Section>
                        </CarouselItem>
                        <CarouselItem index={3}>
                            <Section
                                flex={1}
                                style={{ alignItems: 'center' }}
                                mobile={`margin: 0% 10%; height:calc(100% - 135px); border-radius: 10px; text-align:center;`}
                            >
                                <Paragraph middle>ADVANCED REPORTING</Paragraph>
                                <CenteredBackgroundImage
                                    url={advancedReportingAssetImg}
                                />
                            </Section>
                        </CarouselItem>
                    </Carousel>
                </Section>

                <Section fullHeight>
                    <H1>
                        <OutlinedText>AWARD-WINNING</OutlinedText>
                        <br />
                        <GradientText>IN-HOUSE</GradientText>
                        <br />
                        <span>CREATIVE</span>
                    </H1>
                    <Paragraph>
                        Industry-leading data-driven creative and content
                        capabilities focused on dynamically creating and
                        optimizing your content to drive campaign performance.
                    </Paragraph>
                    <Container gap="5%" row mobile={`flex-direction:column`}>
                        <Container
                            flex={1}
                            mobile={`border:white solid 1px; padding: 10px;padding-bottom:0;margin-bottom:10px`}
                        >
                            <ListHeader>
                                COLLABORATIVE & <br />
                                EASY TO WORK WITH
                            </ListHeader>
                            <Paragraph>
                                We know that approved assets come in all shapes,
                                sizes and file formats – literally. Our creative
                                teams are experts at staying within approved
                                brand guidelines while optimizing across every
                                ad size.
                            </Paragraph>
                        </Container>
                        <Container
                            flex={1}
                            mobile={`border:white solid 1px; padding: 10px;padding-bottom:0;margin-bottom:10px`}
                        >
                            <ListHeader>
                                SPEED OF PRODUCTION
                                <br /> & CRAFT
                            </ListHeader>
                            <Paragraph>
                                Our internal creative teams have a proven track
                                record of working quickly to arrive at
                                best-in-class creative assets even with the most
                                ambitious deadlines.
                            </Paragraph>
                        </Container>
                        <Container
                            flex={1}
                            mobile={`border:white solid 1px; padding: 10px;padding-bottom:0;margin-bottom:10px`}
                        >
                            <ListHeader>
                                CONNECTED TO EXACT
                                <br /> MEDIA PLAN DETAILS
                            </ListHeader>
                            <Paragraph>
                                We deliver custom creative assets with specific
                                CTAs based on the best performance opportunities
                                per channel. Post launch we continuously
                                optimize for performance across all screens and
                                unit sizes for the duration of the campaign.
                            </Paragraph>
                        </Container>
                    </Container>
                    <Marquee style={{ display: 'none' }}>
                        <Container stretch row gap="10%">
                            {[...AwardAssets, ...AwardAssets].map(image => (
                                <Flex center>
                                    <img src={image} height="65" alt="" />
                                </Flex>
                            ))}
                        </Container>
                    </Marquee>
                </Section>
                <Section fullHeight mobile={`background: #fff; color: #000`}>
                    <Paragraph italic style={{ margin: 0 }}>
                        Proprietary Technology & Tools
                    </Paragraph>
                    <H1 mobile={`background: #fff; color: #000`}>
                        <GradientText>reporting,</GradientText>
                        <span>
                            insights
                            <br />& measurements
                        </span>
                    </H1>
                    <Paragraph>
                        We provide comprehensive visual reporting across all
                        channels in a single, unified report to unlock insights
                        with precise efficiency.
                    </Paragraph>
                    {[
                        'Weekly deliverable to track pacing and all key performance metrics',
                        'Granular reporting to include tactics, strategies, creatives, audiences and more',
                        'Informed decision making and optimizations based on learned insights backed by Hadron ID',
                        'Enhanced campaign measurement capabilities',
                    ].map(text => (
                        <ListItem row middle>
                            <img
                                src={RoundedBulletImg}
                                width="40"
                                style={{ marginRight: 10 }}
                                alt={text}
                            />
                            {text}
                        </ListItem>
                    ))}
                </Section>
                <Section fullHeight>
                    <H1>
                        <OutlinedText>DATA SCIENCE</OutlinedText>
                        <br />
                        <GradientText>&</GradientText>
                        <span>ANALYTICS</span>
                    </H1>
                    <Paragraph>
                        Audigent’s Innovation team plays an active role in
                        campaign optimizations, identifying pockets of
                        performance that are developed to exceed campaign goals
                        and objectives.
                    </Paragraph>
                    <Container stretch gap="25px" mobile={`display:none;`}>
                        <Container stretch row gap="25px">
                            <Container flex={1}>
                                <ListHeader>audience insights</ListHeader>
                                <BackgroundImage
                                    style={{
                                        height: '100%',
                                        width: '50%',
                                        right: 0,
                                        margin: '2%',
                                        backgroundImage: `url(${audienceInsightsAssetImg})`,
                                        backgroundPosition: 'top right',
                                    }}
                                />
                                {[
                                    'Demographics',
                                    'Geos',
                                    'Interests and Affinities',
                                    'Web browsing',
                                ].map(text => (
                                    <ListItem row middle light>
                                        <img
                                            src={BulletImg}
                                            width="20"
                                            style={{ marginRight: 10 }}
                                            alt=""
                                        />
                                        {text.toUpperCase()}
                                    </ListItem>
                                ))}
                            </Container>
                            <Container flex={1}>
                                <ListHeader>purchaser insights</ListHeader>
                                <BackgroundImage
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        right: 0,
                                        backgroundImage: `url(${purchaserInsightsAssetImg})`,
                                        backgroundPosition: 'top right',
                                    }}
                                />
                                {[
                                    'User Journey tracking',
                                    'Page exploration insights',
                                    'Average time to convert',
                                    'Purchase intender tracking',
                                    'Add to cart ',
                                    'Checkout initiation',
                                ].map(text => (
                                    <ListItem row middle light>
                                        <img
                                            src={BulletImg}
                                            width="20"
                                            style={{ marginRight: 10 }}
                                            alt=""
                                        />
                                        {text.toUpperCase()}
                                    </ListItem>
                                ))}
                            </Container>
                        </Container>
                        <Container stretch row gap="25px">
                            <Container flex={1}>
                                <ListHeader>
                                    audience segment optimizations
                                </ListHeader>
                                <BackgroundImage
                                    style={{
                                        height: '100%',
                                        width: '50%',
                                        right: 0,
                                        backgroundImage: `url(${audienceSegmentAssetImg})`,
                                        backgroundPosition: 'top center',
                                    }}
                                />
                                {[
                                    'Data-driven optimizations',
                                    'Engagement levels ',
                                    'Modeled audiences',
                                ].map(text => (
                                    <ListItem row middle light>
                                        <img
                                            src={BulletImg}
                                            width="20"
                                            style={{ marginRight: 10 }}
                                            alt=""
                                        />
                                        {text.toUpperCase()}
                                    </ListItem>
                                ))}
                            </Container>
                            <Container flex={1} gap="10">
                                <ListHeader>campaign optimizations</ListHeader>
                                <BackgroundImage
                                    style={{
                                        height: '100%',
                                        width: '50%',
                                        right: 0,
                                        backgroundImage: `url(${campaignOptimizationsAssetImg})`,
                                        backgroundPosition: 'top center',
                                    }}
                                />
                                {[
                                    'In-flight segment iterations',
                                    'Segment refreshes',
                                    'Segment refinement',
                                ].map(text => (
                                    <ListItem row middle light>
                                        <img
                                            src={BulletImg}
                                            width="20"
                                            style={{ marginRight: 10 }}
                                            alt=""
                                        />
                                        {text.toUpperCase()}
                                    </ListItem>
                                ))}
                            </Container>
                        </Container>
                    </Container>
                    <Section container center mobile={`min-height: 400px;`}>
                        <Carousel>
                            <CarouselItem index={0}>
                                <Section
                                    flex={1}
                                    style={{ alignItems: 'center' }}
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px); border-radius: 10px; background-color: #fff; color: #000`}
                                >
                                    <ListHeader>audience insights</ListHeader>
                                    <BackgroundImage
                                        style={{
                                            height: '100%',
                                            width: '50%',
                                            right: 0,
                                            margin: '2%',
                                            backgroundImage: `url(${audienceInsightsAssetImg})`,
                                            backgroundPosition: 'top right',
                                        }}
                                    />
                                    {[
                                        'Demographics',
                                        'Geos',
                                        'Interests and Affinities',
                                        'Web browsing',
                                    ].map(text => (
                                        <ListItem row middle light>
                                            <img
                                                src={BulletImg}
                                                width="20"
                                                style={{ marginRight: 10 }}
                                                alt=""
                                            />
                                            {text.toUpperCase()}
                                        </ListItem>
                                    ))}
                                </Section>
                            </CarouselItem>
                            <CarouselItem index={1}>
                                <Section
                                    flex={1}
                                    style={{ alignItems: 'center' }}
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px); border-radius: 10px; background-color: #fff; color: #000`}
                                >
                                    <ListHeader>purchaser insights</ListHeader>
                                    <BackgroundImage
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            right: 0,
                                            backgroundImage: `url(${purchaserInsightsAssetImg})`,
                                            backgroundPosition: 'top right',
                                        }}
                                    />
                                    {[
                                        'User Journey tracking',
                                        'Page exploration insights',
                                        'Average time to convert',
                                        'Purchase intender tracking',
                                        'Add to cart ',
                                        'Checkout initiation',
                                    ].map(text => (
                                        <ListItem row middle light>
                                            <img
                                                src={BulletImg}
                                                width="20"
                                                style={{ marginRight: 10 }}
                                                alt=""
                                            />
                                            {text.toUpperCase()}
                                        </ListItem>
                                    ))}
                                </Section>
                            </CarouselItem>
                            <CarouselItem index={2}>
                                <Section
                                    flex={1}
                                    style={{ alignItems: 'center' }}
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px); border-radius: 10px; background-color: #fff; color: #000`}
                                >
                                    <ListHeader>
                                        audience segment optimizations
                                    </ListHeader>
                                    <BackgroundImage
                                        style={{
                                            height: '100%',
                                            width: '50%',
                                            right: 0,
                                            backgroundImage: `url(${audienceSegmentAssetImg})`,
                                            backgroundPosition: 'top center',
                                        }}
                                    />
                                    {[
                                        'Data-driven optimizations',
                                        'Engagement levels ',
                                        'Modeled audiences',
                                    ].map(text => (
                                        <ListItem row middle light>
                                            <img
                                                src={BulletImg}
                                                width="20"
                                                style={{ marginRight: 10 }}
                                                alt=""
                                            />
                                            {text.toUpperCase()}
                                        </ListItem>
                                    ))}
                                </Section>
                            </CarouselItem>
                            <CarouselItem index={3}>
                                <Section
                                    flex={1}
                                    style={{ alignItems: 'center' }}
                                    mobile={`margin: 0% 5%; height: calc(100% - 150px);border-radius: 10px; background-color: #fff; color: #000`}
                                >
                                    <ListHeader>
                                        campaign optimizations
                                    </ListHeader>
                                    <BackgroundImage
                                        style={{
                                            height: '100%',
                                            width: '50%',
                                            right: 0,
                                            backgroundImage: `url(${campaignOptimizationsAssetImg})`,
                                            backgroundPosition: 'top center',
                                        }}
                                    />
                                    {[
                                        'In-flight segment iterations',
                                        'Segment refreshes',
                                        'Segment refinement',
                                    ].map(text => (
                                        <ListItem row middle light>
                                            <img
                                                src={BulletImg}
                                                width="20"
                                                style={{ marginRight: 10 }}
                                                alt=""
                                            />
                                            {text.toUpperCase()}
                                        </ListItem>
                                    ))}
                                </Section>
                            </CarouselItem>
                        </Carousel>
                    </Section>
                </Section>
            </Container>
        </Layout>
    );
}
